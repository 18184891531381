import { cacheExchange, createClient, fetchExchange } from "@urql/core";
import shopifyConfig from "../../../.config/shopify/shopify";

const useShopify = () => {
  const { storefrontENV } = useTheme();
  const env = shopifyConfig[storefrontENV];

  const client = createClient({
    url: `https://${env.storeDomain}/api/${env.graphqlApiVersion}/graphql.json`,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => ({
      headers: {
        "X-Shopify-Storefront-Access-Token": env.storefrontToken,
      },
    }),
  });

  const useQuery = (query: any, args = {}) => {
    return client.query(query, args).toPromise();
  };

  const useMutation = (query: any, args = {}) => {
    return client.mutation(query, args).toPromise();
  };

  return { client, useQuery, useMutation };
};

export default useShopify;
