import type { IconName } from "@/types/icons";

const ENV = {
  staging: {
    instanceId: "dutchpet-staging.us.auth0.com",
    clientId: "q179n02gpgAbyDddfMrgQfMQENQmnLU4",
  },
  production: {
    instanceId: "dev-6kpuifbh.us.auth0.com",
    clientId: "o7mlspF1vA6KFkEdXIhyoBceMANrlTKx",
  },
};

enum AuthProvider {
  GOOGLE = "google-oauth2",
  FACEBOOK = "facebook",
}

type SSOType = {
  label: string;
  icon: IconName;
  auth_provider: AuthProvider;
  active: boolean;
};

const SSO_SETTINGS: Readonly<SSOType[]> = [
  {
    label: "Google",
    icon: "social-google",
    auth_provider: AuthProvider.GOOGLE,
    active: true,
  },
  {
    label: "Facebook",
    icon: "social-facebook",
    auth_provider: AuthProvider.FACEBOOK,
    active: false,
  },
];

const useSso = () => {
  const { IS_STAGING } = useTheme();
  const { clientId, instanceId } = ENV[IS_STAGING ? "staging" : "production"];

  const loginUrl = ({
    searchParams,
    search,
  }: {
    searchParams: Record<string, string>;
    search?: string;
  }) => {
    const url = new URL(`https://${instanceId}/authorize`);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("client_id", clientId);
    url.searchParams.set("scope", "SCOPE");
    url.searchParams.set("state", "STATE");
    url.searchParams.set("append_token", "true");

    Object.entries(searchParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    if (search) {
      url.search = search;
    }

    return url.toString();
  };

  const logoutUrl = ({
    searchParams,
  }: {
    searchParams: Record<string, string>;
  }) => {
    const url = new URL(`https://${instanceId}/logout`);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("client_id", clientId);

    Object.entries(searchParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    return url.toString();
  };

  const providers = SSO_SETTINGS.filter(({ active }) => active);

  return {
    providers,
    loginUrl,
    logoutUrl,
  };
};

export { useSso };
